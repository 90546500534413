/*eslint-disable */
import {appConfig} from "../../../config";
import Axios from "axios";
import {UPDATER_SET_CHECK_FILES,UPDATER_SET_CHECK_IMAGES, UPDATER_SET_LAST_TIMESTAMP, UPDATER_SET_CHECK_EMAIL, UPDATER_SET_CHECK_ACTIVITY, UPDATER_SET_CHECK_DASHBOARD} from "../../mutation-types";

export function setLastTimestamp(context, timestamp) {
    if (timestamp > 0) {

    } else {
        timestamp = 0;
    }
    context.commit(UPDATER_SET_LAST_TIMESTAMP, timestamp);
}

export function setEmail(context, timestamp) {
    context.commit(UPDATER_SET_CHECK_EMAIL, timestamp);
}

export function setFiles(context, timestamp) {
    context.commit(UPDATER_SET_CHECK_FILES, timestamp);
}

export function setImages(context, timestamp) {
    context.commit(UPDATER_SET_CHECK_IMAGES, timestamp);
}

export function setDashboard(context, timestamp) {
    context.commit(UPDATER_SET_CHECK_DASHBOARD, timestamp);
}

export function setActivity({commit, state}, timestamp) {
    if (timestamp === 0 || timestamp === null) {
        commit(UPDATER_SET_CHECK_ACTIVITY, null);
    } else if (timestamp > 0 && state.isNeedUpdateActivity === null) {
        commit(UPDATER_SET_CHECK_ACTIVITY, timestamp);
    }
}

export function resetActivity({commit}) {
    commit(UPDATER_SET_CHECK_ACTIVITY, null);
}

export function resetFiles({commit}) {
    commit(UPDATER_SET_CHECK_FILES, null);
}

export function resetImages({commit}) {
    commit(UPDATER_SET_CHECK_IMAGES, null);
}

export function resetDashboard({commit}) {
    commit(UPDATER_SET_CHECK_DASHBOARD, null);
}
