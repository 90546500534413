/*eslint-disable */
import {UPDATER_SET_LAST_TIMESTAMP, UPDATER_SET_CHECK_ACTIVITY, UPDATER_SET_CHECK_EMAIL, UPDATER_SET_CHECK_FILES, UPDATER_SET_CHECK_IMAGES, UPDATER_SET_CHECK_DASHBOARD} from '../../mutation-types';

export default {

    [UPDATER_SET_CHECK_EMAIL](state, timestamp) {
        state.isNeedUpdateEmail = timestamp;
    },

    [UPDATER_SET_CHECK_FILES](state, timestamp) {
        state.isNeedUpdateFiles = timestamp;
    },

    [UPDATER_SET_CHECK_IMAGES](state, timestamp) {
        state.isNeedUpdateImages = timestamp;
    },

    [UPDATER_SET_CHECK_ACTIVITY](state, timestamp) {
        state.isNeedUpdateActivity = timestamp;
    },

    [UPDATER_SET_CHECK_DASHBOARD](state, timestamp) {
        state.isNeedUpdateDashboard = timestamp;
    },

    [UPDATER_SET_LAST_TIMESTAMP](state, timestamp) {
        state.lastTimestamp = timestamp;
    },

};
