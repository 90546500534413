/*eslint-disable */
import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    id: 0,
    name: '',
    businessName: '',
    abn: '',
    phone: '',
    fax: '',
    email: '',
    about: '',
    type:'',
    logoImgPath: '',
    activeXeroAuth: false,
    address: {

    },
    locations: [],
    contacts: {

    },
    mailboxName: '',
    incomingEmailMatching: {
        assessment_number: false,
        claimlinq_claim_number: false,
        rental_number: false,
        insurer_claim_number: false,
        legal_ref: false,
        repairer_quote_number: false,
        repairer_supp_number: false
    },
};


export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
