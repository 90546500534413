/*eslint-disable */
import Axios from "axios";
import {CURRENTCOMPANY_LOAD_INFO, CURRENTCOMPANY_LOGOUT} from "../../mutation-types";
import {States} from '@/store/states';
import Vue from 'vue';

export function loadCompanyInfo(context) {
    try {
        return  Axios.get('/ir/company/info')
            .then(response => {
                if (response.data && response.data.currentCompany) {
                    context.commit(CURRENTCOMPANY_LOAD_INFO, response.data.currentCompany);
                    let state = response.data.currentCompany.address.state,
                        country = response.data.currentCompany.address.country,
                        address = '';
                    address = _.find(States.addressStates, item => {
                        return item.key === state;
                    });
                    //let tm = cityTimeZone.findFromCityStateProvince(address.value+" "+country),
                    // let   timezone = '';
                    //if (!_.isEmpty(tm)) {
                    //    timezone = tm[0].timezone;
                    //}
                    //let tz = momentTimeZone().tz(timezone);
                    //Vue.prototype.$http.defaults.headers.common["CompanyTimeOffset"] = tz.utcOffset()/60;
                }
            })
            .catch(error => {
                console.log(error);
            });
    } catch (e) {
        console.log(e);
    }
};

export function saveBusinessProfile(context, payload) {
    return new Promise((resolve, reject) => {
        Axios.post('/ir/company/update', payload)
        .then(response => {
            loadCompanyInfo(context);
            resolve(response);
        })
        .catch(error =>  {
            reject(error)
        })
    })
}

export function removeCompanyLogo({commit, dispatch}) {
    return new Promise((resolve, reject) => {
        Axios.post(`/ir/company/logo/delete`)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function hideContacts(context) {}

export function saveContacts(context) {}

export function saveLocation(context, payload) {}

export function removeLocations(context) {}

export function logout({commit}) {
    return new Promise((resolve, reject) => {
        commit(CURRENTCOMPANY_LOGOUT);
        resolve();
    });
};