/*eslint-disable */
import {CURRENTCOMPANY_LOAD_INFO, CURRENTCOMPANY_LOGOUT} from '../../mutation-types';

export default {
    [CURRENTCOMPANY_LOAD_INFO](state, ui) {
        state.name = ui.name;
        state.id = ui.id;
        state.businessName = ui.businessName;
        state.about = ui.about;
        state.abn = ui.abn;
        state.phone = ui.phone;
        state.brands = ui.brands;
        state.fax = ui.fax;
        state.email = ui.email;
        state.logoImgPath = ui.logoImgPath;
        state.address = ui.address;
        state.locations = ui.locations;
        state.contacts = ui.contacts;
        if (ui.type == 'Insurance') {
            state.type = 'Insurer';
        } else {
            state.type = ui.type;
        }
        state.activeXeroAuth = ui.activeXeroAuth;
        state.mailboxName = ui.mailboxName;
        state.incomingEmailMatching = ui.incomingEmailMatching;
    },
    [CURRENTCOMPANY_LOGOUT](state) {
        state = {
            id: 0,
            name: '',
            businessName: '',
            abn: '',
            phone: '',
            fax: '',
            email: '',
            about: '',
            type: '',
            logoImgPath: '',
            activeXeroAuth: false,
            address: {
                address: '',
                unitLevelLot: '',
                street: '',
                suburb: '',
                postcode: '',
                state: '',
                country: ''
            },
            locations: [],
            contacts: {}
        };
    }
};
