/* eslint-disable */

import Vue from 'vue';
import App from './App.vue';
//import './registerServiceWorker'
import router from './router';
import store from './store';
import Axios from "axios";
import {appConfig} from "./config.js";
import Vuelidate from "vuelidate";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import './styles/style.css';
import "vue-themify-icons/themify-icons/themify-icons.css";

import BootstrapVue from "bootstrap-vue";
import VueMobileDetection from "vue-mobile-detection";
import moment from 'moment';
import Tabs from './components/rs-tabs';
import VueToast from 'vue-toast-notification';
import VueFormulate from '@braid/vue-formulate';
import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';
import './styles/vue-formulate-input/cl-boilerplate.scss';
import VueSignature from "vue-signature-pad";
import VueFormWizard from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import './styles/vue-form-wizard/custom.scss';
import ZoomOnHover from "vue-zoom-on-hover";

Vue.use(ZoomOnHover);

Vue.use(appConfig);
window.appConfig = appConfig;

Vue.use(Vuelidate);

Vue.use(BootstrapVue);
Vue.use(VueMobileDetection);

Vue.config.productionTip = false;

Axios.defaults.baseURL = appConfig.baseAPIURL;

Vue.prototype.$http = Axios;
const APIToken = localStorage.getItem("token");
if (APIToken) {
    Vue.prototype.$http.defaults.headers.common["Authorization"] = APIToken;
}

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters["auth/isLoggedIn"]) {
            //if (to.matched.some((record) => record.meta.isInsurance)) {
            //  if (store.getters["currentCompany/isInsurance"]) {
            //    next();
            //    return;
            //  }
            //  next("/dashboard");
            //  return;
            //}
            //if (to.matched.some((record) => record.meta.isAccidentManagement)) {
            //  if (store.getters["currentCompany/isAccidentManagement"]) {
            //    next();
            //    return;
            //  }
            //  next("/dashboard");
            //  return;
            //}
            //if (to.matched.some((record) => record.meta.isAssessments)) {
            //  if (store.getters["currentCompany/isAssessments"]) {
            //    next();
            //    return;
            //  }
            //  next("/dashboard");
            //  return;
            //}
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});

Vue.filter("percentage", function (value, decimals) {
    if (!value) {
        value = 0;
    }

    if (!decimals) {
        decimals = 0;
    }

    value = value * 100;
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    value = value + '%';
    return value;
});

Vue.directive("scroll", {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f);
            }
        };
        window.addEventListener("scroll", f);
    },
});

Vue.directive('scroll-into-view', {
    inserted: function (el) {
        el.scrollIntoView({block: "center", inline: "center"});
    }
});

Vue.directive('focus', {
    inserted: function (el) {
        el.focus();
    }
});

Vue.use(Tabs);

//window.$ = require("jquery");
//window.JQuery = require("jquery");

window.NProgress = require('nprogress');

//import 'vue-toast-notification/dist/theme-default.css';
Vue.use(VueToast, {
    duration: 6000,
    position: 'top-right'
});

Vue.use(VueFormulate);
Vue.use(VueSignature);

Vue.use(VueFormWizard);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
