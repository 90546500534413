/*eslint-disable */
import Axios from "axios";
import {
    DASHBOARD_SET_SHOW_COUNT_TASKS,
    DASHBOARD_SET_SHOW_COUNT_EMAILS,
    DASHBOARD_SET_SHOW_OLD_COMMENTS,
    DASHBOARD_SET_SHOW_OLD_EMAILS,
    DASHBOARD_SET_SHOW_OLD_TASKS,
    LOAD_REPORTS,
} from "@/store/mutation-types";

export function setShowCountTasks(context, count) {
    context.commit(DASHBOARD_SET_SHOW_COUNT_TASKS, parseInt(count));
}
export function setShowCountEmails(context, count) {
    context.commit(DASHBOARD_SET_SHOW_COUNT_EMAILS, parseInt(count));
}

export function setShowOldTasks(context, show) {
    context.commit(DASHBOARD_SET_SHOW_OLD_TASKS, !!show);
}
export function setShowOldComments(context, show) {
    context.commit(DASHBOARD_SET_SHOW_OLD_COMMENTS, !!show);
}
export function setShowOldEmails(context, show) {
    context.commit(DASHBOARD_SET_SHOW_OLD_EMAILS, !!show);
}
export function loadReports(context) {
    return Axios.get('/ir/dashboard')
      .then((response) => {
          if(response && response.data && response.data._status){
              context.commit(LOAD_REPORTS, response.data);
          }
      })
      .catch((error) => {
          reject(error);
      });
}