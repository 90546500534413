/*eslint-disable */
import Axios from "axios";
import _ from "lodash";
import {
    INVOICES_UPDATED,
    INVOICES_FILTER_UPDATE,
} from '../../mutation-types';

export function getInvoicesForTableView(context, payload = { page: 1, timestamp:0 }) {

    let page = payload.page;
    let url = '/ir/invoices/page/';
    if(!_.isEmpty(payload.timestamp)) url = '/ir/invoices/'+payload.timestamp+'/page/';

    return Axios.get(url + page)
        .then(response => {
            if(response.data && response.data._status){
                context.commit(INVOICES_UPDATED, response.data.invoices);
                if(response.data._page){
                    page = response.data._page;
                    return context.dispatch('getInvoicesForTableView', {
                        page: response.data._page,
                        timestamp: payload.timestamp
                    });
                }
            }
        })
        .catch(error => {
            console.log(error);
        });

}

export function init(context, payload = 0) {
    return getInvoicesForTableView(context, payload);
}
export function setFilter(context, filter) {
    context.commit(INVOICES_FILTER_UPDATE, filter);
}