/*eslint-disable */
import _ from 'lodash';
import {
    ASSESSMENTS_FILTER_UPDATE,
    ASSESSMENTS_REMOVE,
    ASSESSMENTS_REMOVE_BY_CLAIM,
    ASSESSMENTS_TABLE_SORT_BY_UPDATE,
    ASSESSMENTS_TABLE_SORT_DESC_UPDATE,
    ASSESSMENTS_UPDATED,
    CURRENT_ASSESSMENT_UPDATE,
} from '../../mutation-types';

export default {
    [CURRENT_ASSESSMENT_UPDATE](state, ui) {
        state.currentAssessment = ui;
    },
    [ASSESSMENTS_UPDATED](state, ui) {
        state.items = _.unionBy(ui, state.items, "id");
    },
    [ASSESSMENTS_FILTER_UPDATE](state, ui) {
        state.filter = ui;
    },
    [ASSESSMENTS_TABLE_SORT_BY_UPDATE](state, ui) {
        state.tableSortBy = ui;
    },
    [ASSESSMENTS_TABLE_SORT_DESC_UPDATE](state, ui) {
        state.tableSortDesc = ui;
    },
    [ASSESSMENTS_REMOVE_BY_CLAIM](state, claimId) {
        _.remove(state.items, function (i) {
            return i.claimId == claimId;
        });
    },
    [ASSESSMENTS_REMOVE](state, id) {
        _.remove(state.items, function (i) {
            return i.id == id;
        });
    },
    setSortByForAssessmentsAll(state, ui) {
        state.sortByForAssessmentsAll = ui;
    },
    setSortDescForAssessmentsAll(state, ui) {
        state.sortDescForAssessmentsAll = ui;
    },
    setSortByForAssessmentsNew(state, ui) {
        state.sortByForAssessmentsNew = ui;
    },
    setSortDescForAssessmentsNew(state, ui) {
        state.sortDescForAssessmentsNew = ui;
    },
    setSortByForAssessmentsProcessing(state, ui) {
        state.sortByForAssessmentsProcessing = ui;
    },
    setSortDescForAssessmentsProcessing(state, ui) {
        state.sortDescForAssessmentsProcessing = ui;
    },
    setSortByForAssessmentsWRecoveries(state, ui) {
        state.sortByForAssessmentsWRecoveries = ui;
    },
    setSortDescForAssessmentsWRecoveries(state, ui) {
        state.sortDescForAssessmentsWRecoveries = ui;
    },
    setSortByForAssessmentsOnHold(state, ui) {
        state.sortByForAssessmentsOnHold = ui;
    },
    setSortDescForAssessmentsOnHold(state, ui) {
        state.sortDescForAssessmentsOnHold = ui;
    },
    setSortByForAssessmentsCompleted(state, ui) {
        state.sortByForAssessmentsCompleted = ui;
    },
    setSortDescForAssessmentsCompleted(state, ui) {
        state.sortDescForAssessmentsCompleted = ui;
    },
    setAssessmentsPerPage(state, ui) {
        state.assessmentsPerPage = ui;
    },
    setAssessmentsAllCurrentPage(state, ui) {
        state.assessmentsAllCurrentPage = ui;
    },
    setAssessmentsNewCurrentPage(state, ui) {
        state.assessmentsNewCurrentPage = ui;
    },
    setAssessmentsProcessingCurrentPage(state, ui) {
        state.assessmentsProcessingCurrentPage = ui;
    },
    setAssessmentsWRecoveriesCurrentPage(state, ui) {
        state.assessmentsWRecoveriesCurrentPage = ui;
    },
    setAssessmentsOnHoldCurrentPage(state, ui) {
        state.assessmentsOnHoldCurrentPage = ui;
    },
    setAssessmentsCompletedCurrentPage(state, ui) {
        state.assessmentsCompletedCurrentPage = ui;
    },
};
