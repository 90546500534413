/*eslint-disable */
import Axios from "axios";
import _ from "lodash";
import {ADD_ITEM_PART_FOR_SAVE, EMPTY_ITEMS_FOR_SAVE, PREPARED_ITEMS_FOR_SAVE} from '../../mutation-types';

function saveItemParts(context) {
    context.commit(PREPARED_ITEMS_FOR_SAVE);
    let data = context.state.itemsForSave;
    context.commit(EMPTY_ITEMS_FOR_SAVE, data);
    Axios.post('/ir/items/', data)
            .then((response) => {

            })
            .catch((error) => {

            });
}

var saveDebounced = _.debounce(saveItemParts, 1000);

export function changeItemPart(context, data) {
    context.commit(ADD_ITEM_PART_FOR_SAVE, data);
    saveDebounced(context);
}


