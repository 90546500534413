/*eslint-disable */
import _ from 'lodash';

export function getAllAssessments(state) {
  return state.items;
}

export function getNewAssessments(state) {
    let assessments = _.filter(state.items, function (i) {
        return i.status == "New";
    });
    return assessments;
}
export function getWRecoveriesAssessments(state) {
    let assessments = _.filter(state.items, function (i) {
        return i.status == "WRecoveries";
    });
    return assessments;
}
export function getProcessingAssessments(state) {
    let assessments = _.filter(state.items, function (i) {
        return i.status == "Processing";
    });
    return assessments;
}
export function getOnHoldAssessments(state) {
    let assessments = _.filter(state.items, function (i) {
        return i.status == "OnHold";
    });
    return assessments;
}
export function getCompletedAssessments(state) {
    let assessments = _.filter(state.items, function (i) {
        return i.status == "Completed";
    });
    return assessments;
}
export function countNewAssessments(state) {
    let assessments = _.filter(state.items, function (i) {
        return i.status == "New";
    });
    return assessments.length;
}
export function countWRecoveriesAssessments(state) {
    let assessments = _.filter(state.items, function (i) {
        return i.status == "WRecoveries";
    });
    return assessments.length;
}
export function countProcessingAssessments(state) {
    let assessments = _.filter(state.items, function (i) {
        return i.status == "Processing";
    });
    return assessments.length;
}
export function countOnHoldAssessments(state) {
    let assessments = _.filter(state.items, function (i) {
        return i.status == "OnHold";
    });
    return assessments.length;
}
export function countCompletedAssessments(state) {
    let assessments = _.filter(state.items, function (i) {
        return i.status == "Completed";
    });
    return assessments.length;
}

export function getFilter(state) {
    return state.filter;
}

export  function getSortByForAssessmentsAll(state) {
    return state.sortByForAssessmentsAll;
}
export  function getSortDescForAssessmentsAll(state) {
    return state.sortDescForAssessmentsAll;
}

export  function getSortByForAssessmentsNew(state) {
    return state.sortByForAssessmentsNew;
}
export  function getSortDescForAssessmentsNew(state) {
    return state.sortDescForAssessmentsNew;
}

export  function getSortByForAssessmentsProcessing(state) {
    return state.sortByForAssessmentsProcessing;
}
export  function getSortDescForAssessmentsProcessing(state) {
    return state.sortDescForAssessmentsProcessing;
}

export  function getSortByForAssessmentsWRecoveries(state) {
    return state.sortByForAssessmentsWRecoveries;
}
export  function getSortDescForAssessmentsWRecoveries(state) {
    return state.sortDescForAssessmentsWRecoveries;
}

export  function getSortByForAssessmentsOnHold(state) {
    return state.sortByForAssessmentsOnHold;
}
export  function getSortDescForAssessmentsOnHold(state) {
    return state.sortDescForAssessmentsOnHold;
}

export  function getSortByForAssessmentsCompleted(state) {
    return state.sortByForAssessmentsCompleted;
}
export  function getSortDescForAssessmentsCompleted(state) {
    return state.sortDescForAssessmentsCompleted;
}
export  function getAssessmentsPerPage(state) {
    return state.assessmentsPerPage;
}

export  function getAssessmentsAllCurrentPage(state) {
    return state.assessmentsAllCurrentPage;
}

export  function getAssessmentsNewCurrentPage(state) {
    return state.assessmentsNewCurrentPage;
}

export  function getAssessmentsProcessingCurrentPage(state) {
    return state.assessmentsProcessingCurrentPage;
}

export  function getAssessmentsWRecoveriesCurrentPage(state) {
    return state.assessmentsWRecoveriesCurrentPage;
}

export  function getAssessmentsOnHoldCurrentPage(state) {
    return state.assessmentsOnHoldCurrentPage;
}

export  function getAssessmentsCompletedCurrentPage(state) {
    return state.assessmentsCompletedCurrentPage;
}


