/*eslint-disable */
import _ from 'lodash';
import {CLAIMS_FILTER_UPDATE, CLAIMS_REMOVE, CLAIMS_TABLE_SORT_BY_UPDATE, CLAIMS_TABLE_SORT_DESC_UPDATE, CLAIMS_UPDATED, CURRENT_CLAIM_UPDATE} from '../../mutation-types';

export default {
    [CURRENT_CLAIM_UPDATE](state, ui) {
        // state.currentRfq = ui;
    },
    [CLAIMS_UPDATED](state, ui) {
        state.items = _.unionBy(ui, state.items, "id");
        _.forEach(state.items, function (item) {
            item.filter = "#" + item.systemStatus.replace(/\s+/g, '');
            if(item.isRentalInProgress){
                item.filter = item.filter + ' #RentalInProgress';
            }
        });
    },
    [CLAIMS_FILTER_UPDATE](state, ui) {
        state.filter = ui;
    },
    [CLAIMS_TABLE_SORT_BY_UPDATE](state, ui) {
        state.tableSortBy = ui;
    },
    [CLAIMS_TABLE_SORT_DESC_UPDATE](state, ui) {
        state.tableSortDesc = ui;
    },
    [CLAIMS_REMOVE](state, id) {
        _.remove(state.items, function (i) {
            return i.id == id;
        });
    },
    setClaimsPerPage(state, ui) {
        state.claimsPerPage = ui;
    },
    setClaimsCurrentPage(state, ui) {
        state.claimsCurrentPage = ui;
    },
};
