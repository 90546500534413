/*eslint-disable */

export function getDetails(state) {
  return {
    id: state.id,
    businessName: state.businessName,
    abn: state.abn,
    about: state.about,
    phone: state.phone,
    fax: state.fax,
    email: state.email,
    brands: state.brands,
    type: state.type,
    activeXeroAuth: state.activeXeroAuth,
    address: {
      address: state.address.address,
      unitLevelLot: state.address.unitLevelLot,
      street: state.address.street,
      suburb: state.address.suburb,
      postcode: state.address.postcode,
      country: state.address.country,
      state: state.address.state
    },
    logoImgPath: state.logoImgPath,
    mailboxName: state.mailboxName,
    incomingEmailMatching: state.incomingEmailMatching,
  };
}

export function getCompanyId(state) {
  return state.id;
}

export function getLocations(state) {
  return state.locations;
}

export function getContacts(state) {
  return state.contacts;
}

export function isAssessments(state) {
  return !!(state.type === "Assessments");
}
export function isInsurance(state) {
  return !!(state.type === "Insurance") || !!(state.type === "Insurer") ;
}
export function isAccidentManagement(state) {
  return !!(state.type === "AccidentManagement") || !!(state.type === "Accident Management");
}
export function getCompanyName(state) {
  return state.businessName;
}
