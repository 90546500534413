/*eslint-disable */
import _ from 'lodash';
import {ASSESSORS_UPDATED, CURRENT_ASSESSOR_UPDATE, ASSESSORS_FILTER_UPDATE} from '../../mutation-types';

export default {
    [CURRENT_ASSESSOR_UPDATE](state, ui) {
        state.currentAssessor = ui;
    },
    [ASSESSORS_UPDATED](state, ui) {
        state.items = _.unionBy(ui, state.items, "id");
    },
    [ASSESSORS_FILTER_UPDATE](state, ui) {
        state.filter = ui;
    },
};
