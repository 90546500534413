/*eslint-disable */
import Axios from "axios";
import _ from "lodash";
import {
    LEGAL_FIRMS_UPDATED,
} from '../../mutation-types';

export function getLegalfirms(context, payload = 0) {
// return;
    let url = '/ir/legal-firms';
    if (!_.isEmpty(payload)) url = '/ir/legal-firms/' + payload;

    Axios.post(url)
        .then(response => {
            context.commit(LEGAL_FIRMS_UPDATED, response.data.legalFirms);
        })
        .catch(error => {
            console.log(error);
        });
}

export function init(context, payload = 0) {
    return getLegalfirms(context, payload = 0);
}

