<template>
    <div
            class="wrapper theme-claimlinq"
            :class="[{'is-visible': isLogin || isHome || isForm}]"
            v-show="isLoaded"
            :style="{backgroundColor: !isLoggedIn && !isForm? '#212330':''}"
    >
        <SiteSidebar v-if="isLoggedIn && !isForm"></SiteSidebar>
        <SiteHeader v-if="isLoggedIn && !isForm"></SiteHeader>
        <SiteContent v-if="isLoggedIn && !isForm"></SiteContent>

        <RouterContainer v-else-if="!isLoggedIn || isForm"></RouterContainer>
    </div>
</template>


<script>
    /* eslint-disable */
    import Vue from "vue";

    import {mapGetters} from "vuex";
    import accounting from "accounting";

    import SiteHeader from "./components/common/site-header";
    import SiteSidebar from "./components/common/site-sidebar";
    import SiteContent from "./components/common/site-content";
    import AuthLoginPage from "./views/auth/login";
    import RouterContainer from "./views/container";
    import Axios from "axios";

    import HomePage from "./views/home/Home.vue";

    import Vuelidate from "vuelidate";
    import moment from "moment";

    Vue.use(Vuelidate);

    export default {
        name: "app",
        components: {
            SiteHeader,
            SiteSidebar,
            SiteContent,
            HomePage,
            AuthLoginPage,
            RouterContainer,
        },
        data: function () {
            return {
                isLoaded: false,
                isUpdate: null,
            };
        },
        computed: {
            ...mapGetters({
                isLoggedIn: "auth/isLoggedIn"
            }),
            isHome() {
                return this.$route && this.$route.path == "/";
            },
            isLogin() {
                return this.$route && this.$route.path == "/login";
            },
            isForm() {
                return this.$route && this.$route.meta && this.$route.meta.isForm;
            }
        },
        methods: {
            checkUpdate() {
                if (this.$store && this.$store.getters["auth/isLoggedIn"]) {
                    let timestamp = this.$store.getters["updater/lastTimestamp"];
                    // timestamp = Math.floor(timestamp / 1000) - 1;
                    //console.log('update=', timestamp);
                    Axios.get('/ir/update/' + timestamp)
                        .then(response => {
                            if (response.data && response.data._status) {
                                this.$store.dispatch('updater/setLastTimestamp', response.data._timestamp, {root: true});
                                if (response.data._update) {
                                    if (response.data._update.claim > 0) {
                                        this.$store.dispatch('claim/getClaimsForTableView', response.data._update.timestamp, {root: true});
                                    }
                                    if (response.data._update.assessment > 0) {
                                        this.$store.dispatch('assessment/getAssessmentsForTableView', response.data._update.timestamp, {root: true});
                                    }
                                    if (response.data._update.invoice > 0) {
                                        this.$store.dispatch('invoice/getInvoicesForTableView', {timestamp: response.data._update.timestamp}, {root: true});
                                    }
                                    if (response.data._update.assessor > 0) {
                                        this.$store.dispatch('assessor/init', response.data._update.timestamp, {root: true});
                                    }
                                    if (response.data._update.activity > 0) {
                                        this.$store.dispatch('updater/setActivity', response.data._update.timestamp, {root: true});
                                    }
                                    if (response.data._update.email > 0) {
                                        this.$store.dispatch('updater/setEmail', response.data._update.timestamp, {root: true});
                                    }
                                    if (response.data._update.files > 0) {
                                        this.$store.dispatch('updater/setFiles', response.data._update.timestamp, {root: true});
                                    }
                                    if (response.data._update.images > 0) {
                                        this.$store.dispatch('updater/setImages', response.data._update.timestamp, {root: true});
                                    }
                                    if (response.data._update.dashboard > 0) {
                                        this.$store.dispatch('updater/setDashboard', response.data.update.timestamp, {root: true});
                                    }
                              }

                            }
                        });
                }
            }
        },
        created: function () {
            if (!this.isHome && !this.isLoggedIn) {
                this.$router.push('/');
            }

            let vm = this;
            this.$http.interceptors.response.use(undefined, function (err) {
                // console.log('ERROR:', err, err.response);
                return new Promise(function (resolve, reject) {
                    if (err.response.status === 401) {
                        vm.$store.dispatch("auth/logout");
                    }
                    throw err;
                });
            });

            if (this.$store && this.$store.getters["auth/isLoggedIn"]) {
                this.$store.dispatch("initAppAfterLogin");
            }
            if (this.activeMainTemplateClass || !this.isLoggedIn) this.isLoaded = true;

            //  console.log(vm.isUpdate);
            clearInterval(vm.isUpdate);
            if (!vm.isUpdate) {
                this.isUpdate = setInterval(() => {
                    vm.checkUpdate();
                }, 5000);
            }
        },
        watch: {},

        mounted() {
            this.$nextTick(() => {
                this.isLoaded = true;
            });
        }
    };
    Vue.filter("formatDatetime", function (val) {
      return moment(val).format('DD/MM/YYYY - hh:mma');
    });
    Vue.filter("formatTime", function (val) {
      return moment(val).format('hh:mma');
    });
    Vue.filter("formatDate", function (val) {
      return moment(val).format('DD/MM/YYYY');
    });
    Vue.filter("formatDateShort", function (val) {
      return moment(val).format('D-MMM');
    });
    Vue.filter("UpperCase", function (value) {
        if (!value) {
            return '';
        }
        return _.upperCase(value);
    });
    Vue.filter("formatMoney", function (value) {
      return accounting.formatMoney(value);
    });
    Vue.filter("formatFileSize", function (value) {
      if(!value) value = 0;
      if(value < 1024){
        return value + ' b'
      } else if(value < 1024 ** 2){
        return (value / 1024).toFixed(1) + " Kb"
      } else if(value < 1024 ** 3){
        return (value / (1024 ** 2)).toFixed(1) + " Mb"
      }
      return (value / (1024 ** 3)).toFixed(1) + " Gb"
    });
    Vue.filter('striphtml', function (value) {
        var div = document.createElement("div");
        div.innerHTML = value;
        var text = div.textContent || div.innerText || "";
        return text;
    });
    Vue.filter("reshapeString", function (value) {
        let N= 35;
        return value.split('').reduce((o,c,i) => o+(!i || i%N?'':'\n')+c, '');

    });
</script>

<style lang="scss">
    .wrapper {
        visibility: hidden;
        opacity: 0;
        transition: opacity 100ms ease-out;
        transition-delay: 200ms;
    }

    .wrapper.is-visible,
    .wrapper.theme-claimlinq,
    .wrapper.theme-supplier,
    .wrapper.theme-home {
        visibility: visible;
        opacity: 1;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2C3E50;
    }

    #nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #2C3E50;

            &.router-link-exact-active {
                color: #42B983;
            }
        }
    }

    .autosave {
        color: #142742;
        font-size: 13px;
        margin-left: 100px;
    }

    @media (max-width: 425px) {
        .V3 .page-header h4 .autosave {
            display: block;
            position: absolute;
            right: 15px;
            top: 15px;
        }
    }

    .b-form-btn-label-control.form-control > .btn:hover {
        color: green;
    }

    .b-form-btn-label-control.form-control > .form-control {
        padding-top: 9px;
    }

</style>
