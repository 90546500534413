/*eslint-disable */
import Axios from "axios";
import _ from "lodash";
import {
    ASSESSORS_UPDATED, ASSESSORS_FILTER_UPDATE,
} from '../../mutation-types';

export function getAssessorsForTableView(context, payload = 0) {

    let url = '/ir/assessor';
    if (!_.isEmpty(payload)) url = '/ir/assessors/' + payload;

    Axios.post(url)
        .then(response => {
            context.commit(ASSESSORS_UPDATED, response.data.assessors);
        })
        .catch(error => {
            console.log(error);
        });
}

export function init(context, payload = 0) {
    return getAssessorsForTableView(context, payload = 0);
}

export function setFilter(context, filter) {
    context.commit(ASSESSORS_FILTER_UPDATE, filter);
}

