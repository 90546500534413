/*eslint-disable */
import {
    DASHBOARD_SET_SHOW_COUNT_TASKS,
    DASHBOARD_SET_SHOW_COUNT_EMAILS,
    DASHBOARD_SET_SHOW_OLD_COMMENTS,
    DASHBOARD_SET_SHOW_OLD_EMAILS,
    DASHBOARD_SET_SHOW_OLD_TASKS,
    LOAD_REPORTS,
} from "@/store/mutation-types";

export default {
    [DASHBOARD_SET_SHOW_COUNT_TASKS](state, ui) {
        state.showCountTasks = ui;
    },
    [DASHBOARD_SET_SHOW_COUNT_EMAILS](state, ui) {
        state.showCountEmails = ui;
    },
    [DASHBOARD_SET_SHOW_OLD_COMMENTS](state, ui) {
        state.showOldComments = ui;
    },
    [DASHBOARD_SET_SHOW_OLD_EMAILS](state, ui) {
        state.showOldEmails = ui;
    },
    [DASHBOARD_SET_SHOW_OLD_TASKS](state, ui) {
        state.showOldTasks = ui;
    },
    [LOAD_REPORTS](state, ui) {
        state.reports = ui;
    }
};
