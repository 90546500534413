/*eslint-disable */
import Axios from "axios";
import _ from "lodash";
import {
    ASSESSMENTS_FILTER_UPDATE,
    ASSESSMENTS_TABLE_SORT_BY_UPDATE,
    ASSESSMENTS_TABLE_SORT_DESC_UPDATE,
    ASSESSMENTS_UPDATED,
    ASSESSMENTS_REMOVE_BY_CLAIM,
    ASSESSMENTS_REMOVE,
    CLAIMS_UPDATED
} from '../../mutation-types';

export function getAssesmentById(context, rfqId) {
    return new Promise((resolve, reject) => {
        Axios.get('/ir/assessment/' + rfqId)
            .then((response) => {
                // context.commit(REPAIRER_CURRENT_RFQ_UPDATE, response.data.rfq);
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function setFilter(context, filter) {
    context.commit(ASSESSMENTS_FILTER_UPDATE, filter);
}

export function setTableSortBy(context, filter) {
    context.commit(ASSESSMENTS_TABLE_SORT_BY_UPDATE, filter);
}

export function setTableSortDesc(context, filter) {
    context.commit(ASSESSMENTS_TABLE_SORT_DESC_UPDATE, filter);
}

export function getAssessmentsForTableView({commit, rootGetters, dispatch}, payload = 0) {

//    console.log('getAssessmentsForTableView PAYLOAD!!!!', payload)
    let page = 1;
    let url = '/ir/assessments/page/';
    if (!_.isEmpty(payload)) url = '/ir/assessments/' + payload + '/page/';
  //  console.log('URL', url)

    let loadPage = () => {
        Axios.post(url + page)
            .then(response => {
                commit(ASSESSMENTS_UPDATED, response.data.assessments);
                //console.log("getters['updater/lastTimestamp']",rootGetters['updater/lastTimestamp']);
                //console.log("response.data._timestamp ",response.data._timestamp );
                if (response.data._timestamp && response.data._timestamp > 0 && rootGetters['updater/lastTimestamp'] == 0) {
                    dispatch('updater/setLastTimestamp', response.data._timestamp, {root: true});
                }
                if (response.data._page) {
                    page = response.data._page;
                    loadPage();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
    loadPage();
}

export function init(context, payload = 0) {
    return getAssessmentsForTableView(context, payload);
}

export function deleteAssessmentByClaimId(context, claimId) {
    context.commit(ASSESSMENTS_REMOVE_BY_CLAIM, claimId);
}

export function deleteAssessmentById(context, id) {
    context.commit(ASSESSMENTS_REMOVE, id);
}

