/*eslint-disable */

export function lastTimestamp(state) {
    if (!state.lastTimestamp) {
        return 0;
    }
    return (state.lastTimestamp - 1);
};

export function isNeedUpdateActivity(state) {
    return !(!state.isNeedUpdateActivity || state.isNeedUpdateActivity === null);
};

export function getTimestampForUpdateActivity(state) {
    return state.isNeedUpdateActivity;
};

export function isNeedUpdateFiles(state) {
    return !(!state.isNeedUpdateFiles || state.isNeedUpdateFiles === null);
};

export function isNeedUpdateImages(state) {
    return !(!state.isNeedUpdateImages || state.isNeedUpdateImages === null);
};

export function isNeedUpdateDashboard(state) {
    return !(!state.isNeedUpdateDashboard || state.isNeedUpdateDashboard === null);
};
