/*eslint-disable */

export function getShowCountTasks(state) {
    return state.showCountTasks;
}
export function getShowCountEmails(state) {
    return state.showCountEmails;
}

export function getShowOldTasks(state) {
    return state.showOldTasks;
}

export function getShowOldComments(state) {
    return state.showOldComments;
}

export function getShowOldEmails(state) {
    return state.showOldEmails;
}

export function getReports(state) {
    return state.reports;
}
