/*eslint-disable */
import {ADD_ITEM_PART_FOR_SAVE, EMPTY_ITEMS_FOR_SAVE, PREPARED_ITEMS_FOR_SAVE} from '../../mutation-types';
import _ from "lodash";

export default {
    [ADD_ITEM_PART_FOR_SAVE](state, part) {
        let p = [part];
        state.itemParts = _.unionBy(p, state.itemParts, "id");
    },
    [PREPARED_ITEMS_FOR_SAVE](state) {
        state.itemsForSave=state.itemParts;
        state.itemParts = [];
    },
    [EMPTY_ITEMS_FOR_SAVE](state) {
        state.itemsForSave = [];
    }
};
