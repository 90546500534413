/*eslint-disable */
import Axios from "axios";
import {CURRENTCOMPANY_LOGOUT, CURRENTUSER_LOAD_INFO, CURRENTUSER_UPDATE_USER_AVATAR, CURRENTUSER_LOGOUT} from "../../mutation-types";

export function loadUserInfo(context) {
    return Axios.get('/ir/user/info')
        .then(response => {
                if (response.data && response.data.currentUser) {
                context.commit(CURRENTUSER_LOAD_INFO, response.data.currentUser);
                }
        })
        .catch(error => {
            console.log(error);
        });
};

export function closeActiveSessions() {
    return new Promise((resolve, reject) => {
        Axios.post(`/ir/user/closeActiveSessions`)
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export function updateUserAvatar(context, payload) {
    context.commit(CURRENTUSER_UPDATE_USER_AVATAR, payload);
}

export function updateCurrentUser(context, data) {
    return new Promise((resolve, reject) => {
        Axios.post(`/ir/user`, data)
        .then(res => {
            if (res.data.status) {
                loadUserInfo(context);
                resolve(res);
            }
        })
        .catch(err => {
            reject(err);
        });
    })
}

export function updateCurrentUserPassword(context, data) {
    return new Promise((resolve, reject) => {
        Axios.post(`/ir/user/changePassword`, data)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        });
    })
}

export function removeUserAvatar({commit, dispatch}) {
    return new Promise((resolve, reject) => {
        Axios.post(`/ir/user/photo/delete`)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function logout({commit}) {
    return new Promise((resolve, reject) => {
        commit(CURRENTUSER_LOGOUT);
        resolve();
    });
};
