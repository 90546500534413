export const States = {
    users: {},
    addressStates: [
        {key: "QLD", value: "Queensland"},
        {key: "NT", value: "Northern Territory"},
        {key: "NSW", value: "New South Wales"},
        {key: "VIC", value: "Victoria"},
        {key: "TAS", value: "Tasmania"},
        {key: "SA", value: "South Australia"},
        {key: "WA", value: "Western Australia"},
    ],
    addressCountries: [
        {key: "AU", value: "Australia"},
        {key: "NZ", value: "New Zealand"},
    ],
    vehicleTransmission: [
        {key: "Automatic", value: "Automatic"},
        {key: "Manual", value: "Manual"},
    ],

}
