/*eslint-disable */

export function isLoggedIn(state) {
    if (!!state.token && state.token !== '' && state.token !== 'undefined') {
        return true;
    }
    return false;
};

export function authStatus(state) {
    return state.status;
};
