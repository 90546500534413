/*eslint-disable */
import _ from 'lodash';
import {
    INVOICES_UPDATED, INVOICES_FILTER_UPDATE
} from '../../mutation-types';

export default {
    [INVOICES_UPDATED](state, ui) {
        state.items = _.unionBy(ui, state.items, "id");
    },
    [INVOICES_FILTER_UPDATE](state, ui) {
        state.filter = ui;
    },
    setSortByForInvoicesAll(state, ui) {
        state.sortByForInvoicesAll = ui;
    },
    setSortDescForInvoicesAll(state, ui) {
        state.sortDescForInvoicesAll = ui;
    },
    setSortByForInvoicesAwaitingPayment(state, ui) {
        state.sortByForInvoicesAwaitingPayment = ui;
    },
    setSortDescForInvoicesAwaitingPayment(state, ui) {
        state.sortDescForInvoicesAwaitingPayment = ui;
    },
    setSortByForInvoicesPaid(state, ui) {
        state.sortByForInvoicesPaid = ui;
    },
    setSortDescForInvoicesPaid(state, ui) {
        state.sortDescForInvoicesPaid = ui;
    },
    setInvoicesPerPage(state, ui) {
        state.invoicesPerPage = ui;
    },
    setInvoicesAllCurrentPage(state, ui) {
        state.invoicesAllCurrentPage = ui;
    },
    setInvoicesAwaitingPaymentCurrentPage(state, ui) {
        state.invoicesAwaitingPaymentCurrentPage = ui;
    },
    setInvoicesPaidCurrentPage(state, ui) {
        state.invoicesPaidCurrentPage = ui;
    },
};
