/*eslint-disable */
import _ from 'lodash';

export function getAllInvoices(state) {
    return state.items;
}
export  function getSortByForInvoicesAll(state) {
    return state.sortByForInvoicesAll;
}
export  function getSortDescForInvoicesAll(state) {
    return state.sortDescForInvoicesAll;
}
export  function getSortByForInvoicesAwaitingPayment(state) {
    return state.sortByForInvoicesAwaitingPayment;
}
export  function getSortDescForInvoicesAwaitingPayment(state) {
    return state.sortDescForInvoicesAwaitingPayment;
}
export  function getSortByForInvoicesPaid(state) {
    return state.sortByForInvoicesPaid;
}
export  function getSortDescForInvoicesPaid(state) {
    return state.sortDescForInvoicesPaid;
}
export function getFilter(state) {
    return state.filter;
}

export function getTableSortDesc(state) {
    return state.tableSortDesc;
}

export function getInvoicesPerPage(state) {
    return state.invoicesPerPage;
}
export function getInvoicesAllCurrentPage(state) {
    return state.invoicesAllCurrentPage;
}
export function getInvoicesAwaitingPaymentCurrentPage(state) {
    return state.invoicesAwaitingPaymentCurrentPage;
}
export function getInvoicesPaidCurrentPage(state) {
    return state.invoicesPaidCurrentPage;
}
