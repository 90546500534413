<script>
    /*eslint no-unused-vars: "off"*/
    import Axios from "axios";
    import NProgress from "nprogress";
    import {mapGetters} from "vuex";

    export default {
        name: "site-sidebar",
        data: function () {
            return {
                menu: {

                }
            };
        },
        methods: {
            clickOverlay: function () {
                console.log("overlay");
                let body = document.querySelector("body");
                let first = document.querySelector(".site-header .sidebar-toggle-first");
                if (first) {
                    first.classList.remove("active");
                }
                body.classList.remove("site-sidebar-opened");
            },
            updateSettings: function () {
              NProgress.start();
              Axios.post("/ir/user/config", this.settings)
                  .then(response => {
                    NProgress.done();
                    toastr.success(response.data.msg);
                  })
                  .catch(error => {
                    NProgress.done();
                    toastr.error(error);
                  });
            },
          test(){
            window.sessionStorage.removeItem('assessmentsTableFilter');
            window.sessionStorage.removeItem('assessmentsTableSortBy');
            window.sessionStorage.removeItem('assessmentsTableSortDesc');
          },
        },
        computed: {
            ...mapGetters({
                isAssessments: 'currentCompany/isAssessments',
                isInsurance: 'currentCompany/isInsurance',
                isAccidentManagement: 'currentCompany/isAccidentManagement',
                isUserRoleAssessor: 'currentUser/isRoleAssessor',
                isUserRoleLawyer: 'currentUser/isRoleLawyer',
            }),
            path: function () {
                return this.$route.path;
            },
        },
        watch: {
            path: function () {
            }
        },
        mounted: function () {
        }
    };
</script>

<template>
    <div>
        <!-- Sidebar -->
        <div class="site-overlay" @click="clickOverlay"></div>
        <div class="site-sidebar" @click="clickOverlay">
            <div class="s-logo">
                CLAIMLINQ
            </div>

            <div class="custom-scroll custom-scroll-dark">
                <ul class="sidebar-menu">
                    <li class="menu-title">General</li>
                    <li :class="{'active': path == '/dashboard'}">
                        <router-link to="/dashboard" class="waves-effect waves-light">
                             <span class="s-icon">
                                <i class="bx bx-grid-alt"></i>
                                </span>
                            <span class="s-text">Dashboard</span>
                        </router-link>
                    </li>
                    <li v-if="(isInsurance || isAccidentManagement) && !isUserRoleAssessor && !isUserRoleLawyer" :class="{'active': path == '/claims'}">
                        <router-link to="/claims" class="waves-effect waves-light">
                                    <span class="s-icon">
                                  <i class="bx bx-card"></i>
                                  </span>
                            <span class="s-text">Claims</span>
                        </router-link>
                    </li>
                    <li :class="{'active': path == '/assessments'}" @click="test">
                        <router-link to="/assessments" class="waves-effect waves-light">
                             <span class="s-icon">
                               <i class="bx bx-file"></i>
                             </span>
                            <span class="s-text">Assessments</span>
                        </router-link>
                    </li>
                    <li v-if="!isUserRoleAssessor" :class="{'active': path == '/invoices'}">
                        <router-link to="/invoices" class="waves-effect waves-light" @click="window.localStorage.removeItem('assessmentsTableFilter')">
                         <span class="s-icon">
                              <i class="bx bx-file"></i>
                            </span>
                            <span class="s-text">Invoices</span>
                        </router-link>
                    </li>
                    <li class="menu-title">Maintain</li>
                    <li v-if="!isUserRoleAssessor &&  !isUserRoleLawyer" :class="{'active': path == '/tow-drivers'}">
                      <router-link to="/tow-drivers" class="waves-effect waves-light">
                              <span class="s-icon">
                                <i class="bx bx-file"></i>
                              </span>
                        <span class="s-text">Tow Drivers</span>
                      </router-link>
                    </li>
                  <li v-if="!isUserRoleAssessor &&  !isUserRoleLawyer"
                      :class="{'active': path == '/repairers'}"
                  >
                    <router-link to="/repairers" class="waves-effect waves-light">
                            <span class="s-icon">
                              <i class="bx bx-file"></i>
                            </span>
                      <span class="s-text">Repairers</span>
                    </router-link>
                  </li>
                  <li
                        v-if="(isInsurance || isAccidentManagement) && !isUserRoleAssessor &&  !isUserRoleLawyer"
                        :class="{'active': path.slice(0,9) == '/assessor'}"
                  >
                        <router-link to="/assessors" class="waves-effect waves-light">
                          <span class="s-icon">
                            <i class="bx bx-file"></i>
                          </span>
                            <span class="s-text">Assessors</span>
                        </router-link>
                    </li>
                  <li :class="{'active': path == '/settings'}">
                        <router-link to="/settings" class="waves-effect waves-light">
                          <span class="s-icon">
                            <i class="bx bx-layer"></i>
                          </span>
                            <span class="s-text">Advanced Settings</span>
                        </router-link>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>

<style scoped>
    .skin-3 .site-sidebar {
        background-color: #131923;
        /*background: linear-gradient(180deg, #1C1F3A 0%, #161931 53.69%, #282C48 100%);*/
    }

    .skin-3 .site-sidebar .sidebar-menu .menu-title {
        color: #FFFFFF;
        opacity: 0.25;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .skin-3 .site-sidebar .sidebar-menu li > a {
        color: #FFFFFF;
        margin-left: 15px;
        margin-right: 15px;
        font-size: 14px;
    }

    .skin-3 .site-sidebar .sidebar-menu > li.active > a, .skin-3.compact-sidebar .site-sidebar .sidebar-menu > li:hover {
        /*background-color: #BBC2CE;*/
        background: linear-gradient(135deg, #7267E7 0%, #6259EF 49.12%, #4E45EE 100%);
        margin-left: 15px;
        margin-right: 15px;
        border-radius: 5px;
        color: #FFFFFF;
        margin-top: 1px;
        height: 35px;
    }

    .skin-3 .site-sidebar .sidebar-menu > li:hover > a {
        color: #FFFFFF;
    }

    .skin-3 .site-sidebar .sidebar-menu > li > a .s-icon i {
        color: #FFFFFF;
        font-size: 21px;
    }

    /* Version3 */
    .V3 .site-sidebar {
        /*        background-color: #142742;*/
        margin-top: 0px;
        /* background: linear-gradient(180deg, #1C1F3A 0%, #161931 53.69%, #282C48 100%);*/
        background: none;;
        background-color: #1B1E37;
    }

    .V3 .site-sidebar .sidebar-menu .menu-title {
        color: #FFFFFF;
        opacity: 1;
    }

    .V3 .site-sidebar .sidebar-menu li > a {
        color: #FFFFFF;
        margin-left: 15px;
        margin-right: 15px;
        font-size: 14px;
    }

    .V3 .site-sidebar .sidebar-menu > li.active > a {
        /* background-color: #BBC2CE; */
        background: var(--supplier-color); /* // linear-gradient(135deg, #7267E7 0%, #6259EF 49.12%, #4E45EE 100%); */
        margin-left: 15px;
        margin-right: 15px;
        border-radius: 5px;
        color: #FFFFFF;
        marging-top: 1px;
    }

    .V3 .theme-claimlinq .site-sidebar .sidebar-menu > li.active > a {
        background: var(--repairer-color);
    }

    .V3 .site-sidebar .sidebar-menu > li:hover > a {
        color: #FFFFFF;
    }

    .V3 .site-sidebar .sidebar-menu > li > a .s-icon i {
        color: #FFFFFF;
        font-size: 21px;
    }
</style>

<style>
    /* Version3 sidebar */
    .V3 .site-sidebar {
        position: absolute;
        z-index: 10000;
        top: 0;
        bottom: 0;
        left: 0;
        /* margin-top: 60px;*/
        width: 240px;
        /*background-color: #142742;*/
        background: linear-gradient(180deg, #1C1F3A 0%, #161931 53.69%, #282C48 100%);
    }

    @media (max-width: 767px) {
        .V3 .site-sidebar {
            left: -240px;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
        }

        .V3 .site-sidebar-opened .site-sidebar {
            left: 0;
        }
    }

    .V3 .site-sidebar .custom-scroll {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .V3 .site-sidebar ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .V3 .site-sidebar .sidebar-menu .menu-title {
        margin-top: 21px;
        margin-bottom: 15px;
        padding: 0.5rem 1.25rem;
        font-size: 13px;
        font-weight: 800;
        letter-spacing: 0.93px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.25);
        text-transform: uppercase;
    }

    .V3 .site-sidebar .sidebar-menu li {
        position: relative;
        height: 36px;
        margin-bottom: 0px;
    }

    .V3 .site-sidebar .sidebar-menu li > a {
        display: block;
        position: relative;
        padding: 7px 1.25rem;
        color: rgba(255, 255, 255, 0.7);
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .V3 .site-sidebar .sidebar-menu li > a:hover,
    .site-sidebar .sidebar-menu li.active > a {
        color: #FFFFFF;
    }

    .V3 .site-sidebar .sidebar-menu > li.active > a {
        background: var(--btn-initial-color);
    }

    @media (min-width: 768px) {
        .V3 .site-sidebar .sidebar-menu > li.active > a:after {
            display: none;
        }
    }

    .V3 .site-sidebar .sidebar-menu > li > a .s-icon {
        float: left;
        margin-right: 1.125rem;
    }

    .V3 .site-sidebar .sidebar-menu > li > a .s-icon i {
        position: relative;
        /*font-size: 1.15rem;*/
        color: #FFFFFF;
    }

    .V3 .site-sidebar .sidebar-menu > li > a .s-text {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 0px;
    }

    .V3 .site-sidebar .sidebar-menu > li.with-sub > a .s-caret {
        float: right;
        margin-left: 1rem;
        font-size: 14px;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .V3 .site-sidebar .sidebar-menu > li.with-sub.active > a .s-caret {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    .V3 .site-sidebar .sidebar-menu > li > a .tag {
        float: right;
        margin-top: 2px;
        border-radius: 1rem;
    }

    .V3 .site-sidebar .sidebar-menu ul {
        display: none;
    }

    .V3 .site-sidebar .sidebar-menu ul ul {
        margin-left: 1rem;
        display: none;
    }

    .V3 .site-sidebar .sidebar-menu ul li > a {
        padding: 0.5rem 1rem 0.5rem 3.5rem;
    }

    /* Layout settings */
    .V3 .fixed-sidebar .site-sidebar {
        position: fixed;
        z-index: 111111;
    }

    /*Vesrion3 compact sidebar */

    @media (min-width: 768px) {
        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu li {
            position: relative;
            height: 36px;
            margin-bottom: 0px;
        }

        .V3 .V3.compact-sidebar .site-sidebar {
            width: 70px !important;
        }

        .V3 .V3.compact-sidebar .site-sidebar .custom-scroll,
        .V3 .V3.compact-sidebar .site-sidebar .jspContainer {
            overflow: visible !important;
        }

        .V3 .V3.compact-sidebar .jspVerticalBar {
            width: 0 !important;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu .menu-title {
            display: none;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu li.compact-hide {
            display: none;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu > li:hover {
            margin-left: 15px;
            margin-top: 1px;
            margin-right: 15px;
            border-radius: 5px;
            color: #FFFFFF;
            width: 290px;
            background: none;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu li.active > a {
            background: linear-gradient(
                    135deg,
                    #7267E7 0%,
                    #6259EF 49.12%,
                    #4E45EE 100%
            );
            padding-left: 10px;
            margin: 0px;
            margin-left: 15px;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu li.active:hover > a {
            margin: 0px;
            margin-left: 0px;
            border-radius: 5px;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu > li.active > a:after {
            display: none;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu > li > a {
            margin: 0px;
            margin-left: 15px;
            border-radius: 5px 0px 0px 5px;
            padding: 7px 16px 7px 10px;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu li:hover > a {
            background: linear-gradient(
                    135deg,
                    #7267E7 0%,
                    #6259EF 49.12%,
                    #4E45EE 100%
            );
            margin: 0px;
            margin-left: 0px;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu > li > a .s-icon {
            margin-right: 15px;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu > li > a .s-icon i {
            top: 0;
            font-size: 1.5rem;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu > li > a > .tag {
            position: absolute;
            z-index: 10;
            top: 5px;
            left: 35px;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu > li > a > .s-text,
        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu > li > a > .s-caret {
            display: none;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu > li li > a > .s-caret {
            color: #999999;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu li > ul {
            position: absolute;
            top: 100%;
            left: 70px;
            width: 240px;
            background: #FFFFFF;
            border-left: none;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu li:hover > a > .s-text {
            display: block;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu li:hover > ul {
            display: block !important;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu ul li > a {
            padding: 0.5rem 1rem;
            color: #777777;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu ul li > a:hover,
        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu ul li.active > a {
            color: #333333;
        }

        .V3 .V3.compact-sidebar .site-sidebar .sidebar-menu ul li.active > a {
            font-weight: bold;
        }
    }
    /* Sidebar Logo */
    .V3 .site-sidebar .s-logo {
        font-size: 1.8rem;
        font-weight: 900;
        letter-spacing: 0.3px;
        padding: 15px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #ffffff;
    }
    .V3.compact-sidebar .site-sidebar .s-logo {
        font-size: 0.7rem;
        padding: 20px 8px ;
    }


    /* LOGOS */
    /* For 1x devices */
    .V3 .navbar-brand-logo-svg {
        display: block;
        width: 157px;
        height: 33px;
        margin-left: 15px;
        margin-top: 12px;
    }

    .V3 .compact-sidebar .navbar-brand-logo-svg {
        width: 50px;
        height: auto;
    }

    /* For 2x devices */
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 2) {
        .V3 .navbar-brand-logo-svg {
        }
    }

    /* For 3x devices */
    @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 480dpi) {
        .V3 .navbar-brand-logo-svg {
        }
    }
</style>
