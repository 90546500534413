
const claimFormYrtr = () => import(/* webpackChunkName: "forms" */  '@/forms/yrtr/claimForm');
const RequestLicense = () => import('@/demo/requestLicense');

const formsRoutes = [
    {
        path: '/forms/yrtr',
        name: 'claimFormYrtr',
        component: claimFormYrtr,
    },
    {
        path: '/external/license-form/:code',
        name: 'YRTR Request License',
        component: RequestLicense
    },
    {
        path: '/forms/notice-for-repairer/:hash',
        name: 'FormsNoticeForRepairerView',
        component: () => import(/* webpackChunkName: "forms-repairer" */ "../forms/repairer/repairer-notice-for-repairer"),
        meta: {isForm: true}
    },
];
export default formsRoutes;
